// extracted by mini-css-extract-plugin
export var background = "Facts-module--background--22Ezg";
export var bgFacts = "Facts-module--bgFacts--sI8mU";
export var contentContainer = "Facts-module--contentContainer--CknIP";
export var img = "Facts-module--img--e-sAg";
export var imgMob = "Facts-module--imgMob--EMrXQ";
export var itemFacts = "Facts-module--itemFacts--VI+ey";
export var listFacts = "Facts-module--listFacts--QRWNx";
export var section = "Facts-module--section--L7FkN";
export var svgBackground = "Facts-module--svgBackground--QiN9r";
export var svgContainerFacts = "Facts-module--svgContainerFacts--8gH-u";
export var testSec = "Facts-module--testSec--UIWcV";
export var textContainer = "Facts-module--textContainer--1PDI0";
export var textPr = "Facts-module--textPr--BkVFP";
export var title = "Facts-module--title--r0k+K";
export var titleFacts = "Facts-module--titleFacts--Ru6sJ";