// extracted by mini-css-extract-plugin
export var iconClass = "Steps-module--iconClass--JrrqJ";
export var iconFactsSteps = "Steps-module--iconFactsSteps--IqIGE";
export var itemFacts = "Steps-module--itemFacts--jwz8Y";
export var itemFactsMob = "Steps-module--itemFactsMob--NFcR6";
export var listFacts = "Steps-module--listFacts--arcRg";
export var svgBgFacts = "Steps-module--svgBgFacts--hOai-";
export var svgContainerFacts = "Steps-module--svgContainerFacts--oT6rd";
export var textContainer = "Steps-module--textContainer--3OJPp";
export var textPrP = "Steps-module--textPrP--Eo5Kz";
export var textSteps = "Steps-module--textSteps--3NNp-";
export var titleFacts = "Steps-module--titleFacts--f4dtu";