// extracted by mini-css-extract-plugin
export var burgerMenuActive = "Header-module--burgerMenuActive--A55qJ";
export var burgerMenuButton = "Header-module--burgerMenuButton--o9FMa";
export var burgerMenuClose = "Header-module--burgerMenuClose--ihoxr";
export var burgerMenuLines = "Header-module--burgerMenuLines--1SibC";
export var burgerMenuOverlay = "Header-module--burgerMenuOverlay--oY6IN";
export var header = "Header-module--header--qgajU";
export var headerContainer = "Header-module--headerContainer--IT0aA";
export var icon = "Header-module--icon--Jc+ix";
export var link = "Header-module--link--NKFYz";
export var menuBg = "Header-module--menuBg--VezgZ";
export var menuItem = "Header-module--menuItem--r6QPP";
export var menuLink = "Header-module--menuLink--Y5HHQ";
export var menuList = "Header-module--menuList--Rpfy2";