// extracted by mini-css-extract-plugin
export var accenttext = "Price-module--accenttext--32qgk";
export var acceptmodal = "Price-module--acceptmodal--ZTOXa";
export var background = "Price-module--background--E65Kj";
export var buttonStyled = "Price-module--buttonStyled--sx-z6";
export var checkbox = "Price-module--checkbox--jEkr6";
export var container = "Price-module--container--x3Qpv";
export var donation = "Price-module--donation--WxsAH";
export var economyStyle = "Price-module--economyStyle--c1ldE";
export var formmargin = "Price-module--formmargin--b63vD";
export var formtext = "Price-module--formtext--nTcgc";
export var formtitle = "Price-module--formtitle--4X-49";
export var item = "Price-module--item--tKKcc";
export var list = "Price-module--list--3gEBA";
export var modalform = "Price-module--modalform--Xak65";
export var price = "Price-module--price--L6QGA";
export var priceStyle = "Price-module--priceStyle--xqJLl";
export var subscription = "Price-module--subscription--OUINE";
export var textSuccess = "Price-module--textSuccess--EwZ37";
export var title = "Price-module--title--A3e0a";
export var titleSuccess = "Price-module--titleSuccess--Dz-4f";
export var underformtext = "Price-module--underformtext--PBD3L";