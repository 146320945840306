// extracted by mini-css-extract-plugin
export var accepttext = "Footer-module--accepttext--MxJDb";
export var background = "Footer-module--background--JwCpL";
export var content = "Footer-module--content--Kusw8";
export var discount = "Footer-module--discount--49Cw7";
export var discountText = "Footer-module--discountText--c6VJT";
export var donate = "Footer-module--donate--3HGMM";
export var footerContainer = "Footer-module--footerContainer--WC8Ik";
export var form = "Footer-module--form--MFICJ";
export var gradient = "Footer-module--gradient--5+oil";
export var icf = "Footer-module--icf--sAK-w";
export var link = "Footer-module--link--W4s8p";
export var list = "Footer-module--list--sIYDZ";
export var logo = "Footer-module--logo--ADEig";
export var price = "Footer-module--price--dJa4Y";
export var social = "Footer-module--social--EKfZQ";
export var title = "Footer-module--title--sbCD7";
export var titleContainer = "Footer-module--titleContainer--xVq6p";