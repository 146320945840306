// extracted by mini-css-extract-plugin
export var acceptInMain = "Form-module--acceptInMain--wGMVw";
export var acceptText = "Form-module--acceptText--Hj+VU";
export var background = "Form-module--background--ZJGBT";
export var buttonF = "Form-module--buttonF--vHV+s";
export var check = "Form-module--check--tEsSg";
export var checkError = "Form-module--checkError--t-obC";
export var checkbox = "Form-module--checkbox--uE-tG";
export var checkboxInMain = "Form-module--checkboxInMain--N0p6d";
export var checkboxLabel = "Form-module--checkboxLabel--ZfL-D";
export var content = "Form-module--content--z2PkK";
export var discount = "Form-module--discount--t3SaX";
export var discountText = "Form-module--discountText--mXoeV";
export var emo = "Form-module--emo--Rvr4H";
export var error = "Form-module--error--Ltppk";
export var form = "Form-module--form--MWs3u";
export var formConnect = "Form-module--formConnect--sQMKI";
export var formHi = "Form-module--formHi--NRPzD";
export var formInMain = "Form-module--formInMain--ElwZF";
export var formMargins = "Form-module--formMargins--DBqTM";
export var formSee = "Form-module--formSee--+kw4s";
export var ifYou = "Form-module--ifYou--XadmQ";
export var input = "Form-module--input--38RT5";
export var label = "Form-module--label--Gi1dd";
export var mainContainer = "Form-module--mainContainer--or-Fo";
export var mobContainer = "Form-module--mobContainer--oKl4P";
export var modalAccept = "Form-module--modalAccept--VGmS5";
export var modalcheck = "Form-module--modalcheck--kkodV";
export var modalform = "Form-module--modalform--2YVJY";
export var modalsuccess = "Form-module--modalsuccess--mHbDM";
export var modaltext = "Form-module--modaltext--BnDVF";
export var modaltitle = "Form-module--modaltitle--YHYC8";
export var phonebutton = "Form-module--phonebutton--hWFxR";
export var phonedropdown = "Form-module--phonedropdown--KcwCt";
export var phoneinput = "Form-module--phoneinput--wdC+D";
export var sociaListlInMain = "Form-module--sociaListlInMain--mE6kx";
export var soctitle = "Form-module--soctitle--kncyl";
export var tabContainer = "Form-module--tabContainer--R2TMG";