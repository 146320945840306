// extracted by mini-css-extract-plugin
export var iconClass = "Page-module--iconClass--SCKse";
export var iconFacts = "Page-module--iconFacts--wqGEv";
export var itemFacts = "Page-module--itemFacts--DMfNw";
export var itemFactsMob = "Page-module--itemFactsMob--9rCHz";
export var listFacts = "Page-module--listFacts--bpdPq";
export var svgBgFacts = "Page-module--svgBgFacts--8IFSr";
export var svgContainerFacts = "Page-module--svgContainerFacts--a3uF-";
export var testSec = "Page-module--testSec--M+WHu";
export var textContainer = "Page-module--textContainer--kenpN";
export var textPr = "Page-module--textPr--GKJq8";
export var titleFacts = "Page-module--titleFacts--otZB5";