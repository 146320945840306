// extracted by mini-css-extract-plugin
export var background = "Formula-module--background--hOod3";
export var formula = "Formula-module--formula--6uaRb";
export var formulaContainer = "Formula-module--formulaContainer--GZda4";
export var icon = "Formula-module--icon--BHz95";
export var iconArrow = "Formula-module--iconArrow--5US9f";
export var iconBg = "Formula-module--iconBg--HuOop";
export var iconFormula = "Formula-module--iconFormula--FkBgB";
export var itemFormula = "Formula-module--itemFormula--SS9h7";
export var list = "Formula-module--list--tKHPo";
export var listFormula = "Formula-module--listFormula--sfufq";
export var svgBgFormula = "Formula-module--svgBgFormula--BbJXA";
export var svgContainerFormula = "Formula-module--svgContainerFormula--ojLyI";
export var testSec = "Formula-module--testSec--99w0s";
export var textContainerFormula = "Formula-module--textContainerFormula--yzs8G";
export var title = "Formula-module--title--T213t";